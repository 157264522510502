@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
  
  }
  
  
  /* Off Canvas */
  #offcanvas-menu {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 200px;
    background-color: #005DAB;
    padding: 20px;
    transition: right 0.3s ease-in-out;
    color: white;
    z-index: 1;
  }
  
  #offcanvas-menu.active {
    right: 0;
  }
  
  .close-icon {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Clsoe */
  
  
  /* Banner */
  .Banner {
    background-image: url(./assets/images/banner.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center !important;
  }
  
  /* Clsoe */
  
  
  /* carousel */
  .carousel-indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  
  }
  
  .carousel-indicator {
    width: 28px;
    height: 5px;
    background-color: #E0E0E0;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 100px;
  }
  
  .carousel-indicator:first-child {
    background-color: #f26649;
  }
  
  /* close */
  
  
  
  /* nav dash */
  .text-with-dash {
    position: relative;
    display: inline-block;
  }
  
  .text-with-dash::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: #F26649;
  }
  
 .text-with-dash:hover::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 50px;
    height: 2px;
    background-color: #F26649;
  }
  /* clsoe */
  
  
  .iconify-icon {
    display: inline-block;
  }
  .active\:text-\[\#005DAB\]:active{
    color: #005DAB !important;
    font-weight: 500;
  }
.active:active{
  color: #005DAB !important;
    font-weight: 500;
}